.wysiwyg {

    p {
        margin-bottom: (22/16) * 1em;
        &:last-child {
            margin-bottom: 0;
        }
    }

    a:not([class]) {
        text-decoration: underline;
        text-decoration-color: currentColor;
        transition: text-decoration-color 0.15s;
        @media (hover: hover) {
            &:hover {
                text-decoration-color: transparent;
                @supports (not (text-decoration-color: transparent)) {
                    text-decoration: none;
                }
            }
        }
        @at-root & {
            text-decoration-thickness: 1px;
        }
    }
}
