/* =================
    Flickity reset styles
    https://flickity.metafizzy.co
================== */

.flickity-enabled {
    position: relative;
}

.flickity-viewport {
    position: relative;
    width: 100%;
    height: 100%;
}

.flickity-slider {
    position: absolute;
    width: 100%;
    height: 100%;
}

/* draggable */

.flickity-enabled.is-draggable {
    -webkit-tap-highlight-color: transparent;
    tap-highlight-color: transparent;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.flickity-enabled.is-draggable .flickity-viewport {
    cursor: move;
    cursor: -webkit-grab;
    cursor: grab;
}

.flickity-enabled.is-draggable .flickity-viewport.is-pointer-down {
    cursor: -webkit-grabbing;
    cursor: grabbing;
}

// Make slider and slides relative, not absolute, for the Slider component
// Reason is that we use flexbox to space the slides properly
// The only Flickity feature that really breaks without position: absolute is wrapAround, so YOLO
[data-component="Slider"] {
    .flickity-slider {
        display: flex;
        position: relative;
        > * {
            position: relative !important;
            left: 0 !important;
        }
    }
}
