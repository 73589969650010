.plug {

    $this: &;

    &:nth-of-type(odd) {
        #{$this}__img {
            @apply m:order-0 m:col-start-1;
        }
        #{$this}__text {
            @apply m:order-1 m:col-start-8;
        }
    }

    &__img {
        > * {
            @media (orientation: landscape) {
                max-width: calc((100vh - 80px) * var(--ratio));
                @apply mx-auto;
            }
        }
    }

}
