.search {
    input:not(:placeholder-shown),
    textarea:not(:placeholder-shown),
    input:focus,
    textarea:focus { // &.js-has-value
        & + label {
            opacity: 0;
        }
    }
}
