.hero {
    &__text {
        @screen mp {
            align-self: center;
            @media (min-aspect-ratio: 4/3) {
                min-height: 100vh;
                align-self: start;
            }
        }
    }
}
