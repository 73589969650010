.video {

    max-width: calc(80vh * var(--ratio));

    &__btn {

        width: 100px;
        height: 100px;

        @screen sp {
            width: 130px;
            height: 130px;
        }

        @screen l {
            width: 150px;
            height: 150px;
        }

        @media (hover: hover) {
            &-circle {
                transition: transform 0.3s;
                a:hover & {
                    transform: scale(1.2);
                }
            }
        }
    }

    &__img {
        @media (hover: hover) {
            transition: transform 1s $easeOutQuint;
            transform: scale(1.01);
            a:hover & {
                transform: scale(1);
            }
            @include reduceMotionQuery {
                transform: none !important;
            }
        }
    }
}
