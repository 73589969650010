.input {
    input,
    textarea {
        & + label > span {
            transition: transform 0.3s ease;
        }
    }
    input {
        height: 84px;
        @screen l {
            height: 90px;
        }
    }
    textarea {
        height: 204px;
        resize: none;
        @screen l {
            height: 210px;
        }
    }
    label > span {
        transform-origin: left top;
    }
    input:not(:placeholder-shown),
    textarea:not(:placeholder-shown),
    input:focus,
    textarea:focus { // &.js-has-value
        & + label > span {
            transform: translate(0, -10px) scale(0.77777778, 0.77777778);
            @screen l {
                transform: translate(0, -10px) scale(0.63636364, 0.63636364);
            }
        }
    }
    input:not(:placeholder-shown),
    input:focus {
        padding-top: 52px;
    }
    textarea:not(:placeholder-shown),
    textarea:focus {
        padding-top: 42px;
    }
}
