.badge {

    width: 80px;
    height: 80px;

    @screen l {
        width: 90px;
        height: 90px;
    }

    &__wrap {
        transition: transform 0.3s $easeInOutQuad;
    }

    &:hover &__wrap {
        transform: scale(1.2);
    }

    &__gfx {
        transform: translate(0, -33.3333%);
        transition: transform 0.3s $easeInOutQuad;
    }

    &:hover &__gfx,
    html.outline &:focus &__gfx {
        transform: translate(0, -66.6666%);
    }

    &.is-up:hover &__gfx,
    html.outline &.is-up:focus &__gfx {
        transform: translate(0, 0);
    }
}
