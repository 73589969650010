.rich-text {

    $this: &;

    p:not(:last-child) {
        margin-bottom: (22/16) * 1em;
    }

    h1, h2 {
        @apply font-extended text-26 l:text-32 break-words;
        &:not(:last-child) {
            margin-bottom: 1.538em;
        }
        &:not(:first-child) {
            margin-top: 2.813em;
        }
    }

    h3, h4, h5, h6 {
        @apply font-medium;
        font-size: inherit;
        &:not(:last-child) {
            margin-bottom: 0.455em;
        }
        &:not(:first-child) {
            margin-top: 4.091em;
        }
    }

    ul, ol {
        &:not(:last-child) {
            margin-bottom: (32/16) * 1em;
        }
        &:not(:first-child) {
            margin-top: (32/16) * 1em;
        }
    }

    li {
        padding-left: 1.182em;
        position: relative;
        margin-bottom: (30/22) * 0.45em;

        &::before {
            position: absolute;
            left: 0;
            top: 0;
        }
    }

    ul li::before {
        content: "";
        display: block;
        width: 0.54em;
        height: 0.54em;
        border-radius: 100%;
        top: 0.45em;
        background-color: currentColor;
    }

    ol {
        counter-reset: list;
    }

    ol li {
        counter-increment: list;
    }

    ol li::before {
        content: counter(list) ".";
    }

    ol ol li::before {
        content: counter(list, lower-alpha) ".";
    }

    li ul,
    li ol {
        padding-top: (32/20) * 1em;
    }

    strong, b {
        font-weight: 500;
    }

    em, i {
        font-style: italic;
    }

}
