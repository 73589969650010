.filters {
    &__btn {
        min-width: 100%;
        @screen s {
            min-width: 180px;
        }
        .icon {
            transition: transform 0.3s;
        }
        .is-expanded & {
            @apply bg-black text-transparent;
            .icon {
                transform: scale(1, -1);
                @apply text-white;
            }
        }
    }
    &__items,
    &__items[hidden] {
        @screen l {
            height: auto !important;
            display: block !important;
        }
    }
}
