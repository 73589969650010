/* ==============================
    Basic/generic global styles
 ============================== */

body {
    @apply font-default text-base;
    transition: background-color 1s;
}

.lazyload,
.lazyloading {
//html.js img.load {
    opacity: 0;
    transform: translateZ(0);
    @include reduceMotionQuery {
        transform: none !important;
        opacity: 1 !important;
    }
}

.lazyloaded {
//html.js img.load.loaded {
    opacity: 1;
    transition: opacity 0.3s $easeOutQuad;
}

.no-js {
    img.lazyload {
        display: none;
    }
}

button, a {
    touch-action: manipulation;
    border-radius: 0;
    &[disabled] {
        cursor: default;
    }
}

button {
    appearance: none;
    font-weight: normal;
}

[hidden] {
    display: none !important;
}

input:required {
    box-shadow: none;
}

html.no-outline * {
    outline: none;
}

select {
    appearance: none;
}

::-moz-selection {
    background: #000000;
    color: #ffffff;
}

::selection {
    background: rgba(#000000, 0.99);
    color: #ffffff;
}

video[playsinline] {
    pointer-events: none;
}

*:target {
    scroll-margin-top: 33vh;
}

a {
    text-underline-offset: 1px;
}

*[class*="hover:underline"] {
    text-decoration: underline;
    text-decoration-color: transparent;
    transition: text-decoration-color 0.3s;
    @supports (not (text-decoration-color: transparent)) {
        text-decoration: none;
    }
    @media (hover: hover) {
        &:hover {
            text-decoration: underline !important;
            text-decoration-color: currentColor !important;
            @supports (not (text-decoration-color: transparent)) {
                text-decoration: underline !important;
            }
        }
    }
}

*[class*="hover:no-underline"] {
    transition: text-decoration-color 0.3s !important;
    @media (hover: hover) {
        &:hover {
            text-decoration: underline !important;
            text-decoration-color: transparent !important;
            @supports (not (text-decoration-color: transparent)) {
                text-decoration: none !important;
            }
        }
    }
}

// Skip link
a.skip:not(:focus) {
    @apply sr-only;
}

// Heavy links
.link {

    $this: &;

    > span:not(.icon) {
        border-bottom: 1px solid currentColor;
        line-height: 1.2;
        padding-bottom: 0.2em;
        transition: border-bottom-color 0.3s;
    }

    &.inverted > span:not(.icon) {
        border-bottom-color: transparent;
    }

    @media (hover: hover) {
        &:hover > span:not(.icon),
        a:hover & > span:not(.icon) {
            border-bottom-color: transparent;
        }
        &.inverted:hover > span:not(.icon),
        a:hover &.inverted > span:not(.icon) {
            border-bottom-color: currentColor;
        }
    }

}

.icon {

    *[stroke^="#"] {
        stroke: currentColor;
    }

    width: 22px;
    height: 22px;
    stroke-width: 1.5px;

    @include gpu;

    @screen m {
        width: 24px;
        height: 24px;
    }

    &.medium {
        width: 26px;
        height: 26px;
        @screen m {
            width: 28px;
            height: 28px;
        }
    }

    svg {
        width: 100%;
        height: 100%;
    }

    // Animations
    @media (hover: hover) {
        [data-anim] {
            transition: transform 0.3s;
        }
        a:hover & [data-anim="right"],
        button:hover & [data-anim="right"] {
            transform: translate(0.25em, 0);
        }
        & [data-anim="upright"] {
            transform-origin: left bottom;
        }
        a:hover & [data-anim="upright"],
        button:hover & [data-anim="upright"] {
            transform: translate(0.25em, -0.25em);
        }
        a:hover & [data-anim="down"],
        button:hover & [data-anim="down"] {
            transform: translate(0, 0.25em);
        }
        [data-anim="dl"] g[class$="arrow"] {
            transition: transform 0.3s;
        }
        a:hover & [data-anim="dl"],
        button:hover & [data-anim="dl"] {
            g[class$="arrow"] {
                transform: translate(0, 0.1em);
            }
        }
    }
}

video[playsinline] {
    pointer-events: none;
}

/* clears the 'X' from Internet Explorer */
input[type=search]::-ms-clear,
input[type=search]::-ms-reveal {
    display: none;
    width: 0;
    height: 0;
}

/* clears the 'X' from Chrome */
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
    display: none;
}

.content-block {

    @apply my-50 l:my-100;

    scroll-margin-top: 50px;

    @screen l {
        scroll-margin-top: 100px;
    }

    &.extra-space {
        @apply my-100 l:my-200;
    }

    &.bleed:last-child {
        margin-bottom: 0;
    }

    &.rich-text.extra-space + &.readmore {
        @apply -mt-50 l:-mt-100;
    }

    &.readmore + &.rich-text {
        @apply l:mt-0;
    }

    &.intro + &.rich-text {
        @apply mt-50 l:mt-100;
    }

    &.intro.extra-space + &.rich-text {
        @apply -mt-40 l:-mt-100;
    }

    &.linklist + &.linklist {
        @apply -mt-50 l:-mt-100;
    }

}

.content-page {
    @apply mt-50 mb-100 l:mt-100 l:mb-200;
}

.hover-scale {
    @media (hover: hover) {
        transition: transform 0.5s;
        a:hover & {
            transition: transform 1s $easeOutQuint;
            transform: scale(1.03, 1.03);
            @include reduceMotionQuery {
                transform: none;
            }
        }
    }
}

html.js:not(.js-error):not(.is-preview.is-iframe) [data-reveal]:not([data-revealed]) {
    opacity: 0.001;
    @include reduceMotionQuery {
        transform: none !important;
        opacity: 1 !important;
    }
}

/* The typewriter cursor effect */
@keyframes blink-caret {
    from, to { opacity: 0 }
    50% { opacity: 1 }
}

.caret {
    animation: blink-caret .75s step-end infinite;
    @include reduceMotionQuery {
        display: none !important;
    }
}

html.reduce-motion {
    *, *:before, *:after {
        animation: none !important;
        transition: none !important;
    }
}

@include reduceMotionQuery {
    *, *:before, *:after {
        animation: none !important;
        transition: none !important;
    }
}

html.js [data-color] {
    background-color: transparent;
}
