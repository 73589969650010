.header {

    $this: &;

    &__logo {
        width: 200px;
        height: 42px;

        @screen l {
            width: 246px;
            height: 52px;
            transition: transform 1s;
            #{$this}.is-fixed.is-mini & {
                transform: translate(0, -20px);
            }
        }
    }

    &__search {
        &-icon {
            width: 30px;
            height: 30px;
        }
        @screen l {
            transition: transform 1s;
            #{$this}.is-fixed.is-mini & {
                transform: translate(0, -20px);
            }
        }
        @screen lp {
            &-icon {
                width: 24px;
                height: 24px;
            }
        }
        @media (hover: hover) {
            circle {
                transform-origin: center center;
                transition: transform 0.3s;
                @screen lp {
                    transform: none !important;
                }
            }
            &:hover {
                circle {
                    transform: scale(1.1);
                }
            }
        }
    }

    &__burger {
        width: 30px;
        height: 30px;
        &-bar {
            height: 2px;
            transform-origin: right center;
            transition: transform 0.3s 0.3s $easeOutBack, width 0.3s;
            > span {
                transform-origin: center center;
                transition: transform 0.3s;
            }
            &:first-child {
                width: 75%;
                [aria-expanded="true"] & {
                    transition: transform 0.3s, width 0.3s;
                    transform: translateY(8px);
                    width: 100% !important;
                    > span {
                        transition: transform 0.3s 0.15s $easeOutBack;
                        transform: rotate(45deg) scaleX(1.4);
                    }
                }
            }
            &:nth-child(2) {
                top: 8px;
                [aria-expanded="true"] & {
                    > span {
                        transition: transform 0s 0.15s;
                        transform: scaleX(0);
                    }
                }
            }
            &:nth-child(3) {
                top: 16px;
                width: 50%;
                [aria-expanded="true"] & {
                    transition: transform 0.3s, width 0.3s;
                    transform: translateY(-8px);
                    width: 100% !important;
                    > span {
                        transition: transform 0.3s 0.15s $easeOutBack;
                        transform: rotate(-45deg) scaleX(1.4);
                    }
                }
            }
        }
        @screen l {
            transition: transform 1s;
            #{$this}.is-fixed.is-mini & {
                transform: translate(0, -20px);
            }
        }
    }

    @media (hover: hover) {
        &__burger:hover &__burger-bar {
            width: 100%;
        }
    }

    &__inner {
        #{$this}.is-fixed & {
            position: fixed;
        }
        #{$this}.menu-open & {
            position: fixed !important;
            transform: none !important;
            > * {
                transform: none !important;
            }
        }
    }

    &__menu {
        @screen lp {
            transition: transform 1s;
            #{$this}.is-fixed.is-mini & {
                transform: translate(0, -20px);
            }
            > * {
                opacity: 1 !important;
            }
        }
    }

    &__bg {
        transition: opacity 0.3s, transform 0.3s;//, background-color 0.1s;
        pointer-events: none;

        #{$this}.is-fixed.is-mini & {
            transition: opacity 0.3s, transform 0.3s, background-color 1s;
            pointer-events: auto;
        }

        @screen l {
            #{$this}.is-fixed & {
                opacity: 0.9;
            }
            #{$this}.is-fixed.is-mini & {
                transform: translate(0, -20px);
            }
        }
    }

}
