.form {
    $this: &;
    &__checkboxes + &__checkboxes,
    &__radiobuttons + &__radiobuttons,
    &__checkboxes + &__radiobuttons,
    &__radiobuttons + &__checkboxes,
    &__text + &__checkboxes,
    &__text + &__radiobuttons {
        margin-top: 20px;
    }
    &__receipt {
        max-width: 320px;
        html.no-js &[hidden]:target {
            display: flex !important;
        }
        html.no-js &[hidden]:target + #{$this}__submit {
            opacity: 0;
        }
    }
}
