.btn {
    .submitting &[type="submit"] {
        span {
            opacity: 0;
        }
        [data-spinner] {
            opacity: 1;
        }
    }

    &.small {
        height: 42px;
    }
}
