/**
========================
Custom Tailwind utilities
https://tailwindcss.com/docs/adding-new-utilities
========================

All Tailwind-powered utilities need to render inside the `@layer utilities` directive.
Tailwind will automatically create responsive classes for any utilities rendered inside the `@responsive` directive.
If you need Tailwind to create pseudo-class variant classes (e.g. for :hover or :focus), use the `@variants` directive.
The `@variants` and `@responsive` directives can be combined.

https://tailwindcss.com/docs/adding-new-utilities#generating-responsive-variants
https://tailwindcss.com/docs/adding-new-utilities#generating-pseudo-class-variants

 */

@layer utilities {

    @responsive {

        .gpu {
            @include gpu();
        }

        .anti {
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
        }

        .round {
            border-radius: 100%;
        }

        .full {
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
        }

        .center {
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
        }

        .center-x {
            left: 50%;
            transform: translateX(-50%);
        }

        .center-y {
            top: 50%;
            transform: translateY(-50%);
        }

        .scrollable {
            @include scrollable();
            overscroll-behavior: none;
        }

        .scrollable-x {
            @include scrollableX();
        }

        .no-scrollbar {
            /* Hide scrollbar for Chrome, Safari and Opera */
            &::-webkit-scrollbar {
                display: none;
            }
            /* Hide scrollbar for IE, Edge and Firefox */
            -ms-overflow-style: none;  /* IE and Edge */
            scrollbar-width: none;  /* Firefox */
        }

        .ellipsis {
            text-overflow: ellipsis;
            overflow: hidden;
            display: inline-block;
            max-width: 100%;
            white-space: nowrap;
        }

        .hit {
            &::before {
                content: "";
                display: block;
                width: 100%;
                height: 100%;
                min-width: 44px;
                min-height: 44px;
                position: absolute;
                left: 50%; top: 50%;
                transform: translate(-50%, -50%);
            }
        }

        .bleed {
            padding-left: 0 !important;
            padding-right: 0 !important;
        }

        .js {
            html.no-js & {
                display: none !important;
            }
        }

        .no-js {
            html.js & {
                display: none !important;
            }
        }

        .clickable {
            transition: color 0.3s, background-color 0.3s, transform 0.1s linear;
            &:active {
                transform: scale(0.95);
            }
        }

        .hyphens {
            hyphens: auto;
        }

        .no-hyphens {
            hyphens: none;
        }

    }

}
