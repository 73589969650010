/**
    Feature
 */
.widget-feature {
    &__text {
        @screen m {
            min-width: 340px;
            max-width: 640px;
        }
        @screen l {
            min-width: 420px;
        }
    }
    &__img {
        @screen m {
            width: calc(((var(--vh, 1vh) * 80) * var(--ratio, (3/4))) - 160px);
            max-width: 640px;
        }
    }
}

/**
    Info
 */
.widget-info {
    &__text {
        max-width: 820px;
    }
}

/**
    HTML
 */
.widget-html {
    &__text {
        max-width: 980px;
    }
}

/**
    FORM
 */
.widget-form {
    &__text {
        max-width: 640px;
        @screen l {
            max-width: 780px;
        }
    }
}
